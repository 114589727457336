import {priceFormatter} from "../helpers";
import Swiper from "swiper/bundle";

import 'swiper/css/bundle';

;(function (_, $) {

  let thumbsSwiper;

  if ($('.thumbnails-slider-wrapper').length > 0) {
    thumbsSwiper = new Swiper(".thumbnails-slider-wrapper", {
      loop: true,
      lazy: true,
      spaceBetween: 16,
      slidesPerView: 5,
      freeMode: false,
      watchSlidesProgress: true,
    });
  }

  const mainSwiper = new Swiper(".main-slider-wrapper", {
    loop: !!thumbsSwiper,
    lazy: true,
    spaceBetween: 10,
    thumbs: {
      swiper: thumbsSwiper ?? null,
    },
  });

  const upsellProductsSwiper = new Swiper(".upsell-slider-wrapper", {
    lazy: true,
    spaceBetween: 10,
    slidesPerView: 2,
    autoHeight: true,
    breakpoints: {
      640: {
        slidesPerView: 3
      },
      1024: {
        slidesPerView: 4
      },
      1400: {
        slidesPerView: 5
      }
    }
  });

  const topSellerProductsSwiper = new Swiper(".top-seller-slider-wrapper", {
    lazy: true,
    spaceBetween: 10,
    slidesPerView: 2,
    autoHeight: true,
    breakpoints: {
      640: {
        slidesPerView: 3
      },
      1024: {
        slidesPerView: 4
      },
      1400: {
        slidesPerView: 5
      }
    }
  });

  const changeToDiscountedPrice = ($priceElement, newPrice) => {
    const priceMarkup = TPK.formats.discounted_price_format
      .replace('{{price}}', priceFormatter.format(newPrice))
      .replace('{{initial_price}}', priceFormatter.format($priceElement.data('fppi')));

    $priceElement.html(priceMarkup);
  }

  const changeToFullPrice = ($priceElement) => {
    const priceMarkup = TPK.formats.price_format.replace('{{price}}', priceFormatter.format($priceElement.data('fppi')));

    $priceElement.html(priceMarkup);
  }

  const getPriceElement = $el => $el.closest('.tpk-product-add-to-cart').find('.price');

  const scrollToElement = selector => {
    window.scrollTo({top: $(selector).offset().top - 60, behavior: 'smooth'});
  }

  $('#single-product-video-modal')
    .on('modal:open', function () {
      const iframe = $('#product-video-iframe').html();

      $('#single-product-video-modal').append(iframe);
    })
    .on('modal:after-close', function () {
      $('#single-product-video-modal > iframe').remove()
    });

  $('.quantity-discount-options-wrapper').on('change', 'input[type="radio"]', function () {
    $('.variations_form input.qty[name="quantity"]').val($(this).val());

    changeToDiscountedPrice(getPriceElement($(this)), $(this).data('ppi'), $(this).data('fppi'));
  });

  $('.variations_form').on('change', 'input.qty[name="quantity"]', function () {
    const quantityOptions = $('.quantity-discount-options-wrapper input[type="radio"]');
    const thisValue = +$(this).val();

    const priceElement = getPriceElement($(this));

    quantityOptions.each(function () {
      if (+$(this).prop('value') === thisValue) {
        $(this).trigger('click');
      } else if (thisValue === 1) {
        $(this).prop('checked', false);

        changeToFullPrice(priceElement)
      }
    })
  });

  $('#tpk-toggle-more-bundles').on('click', function () {
    $(this).closest('.tpk-product-bundles').addClass('visible');
  })

  $('#tpk-toggle-full-description').on('click', function () {
    $(this).closest('.tpk-product-description').addClass('visible');
  })

  $('.tpk-product-jump-navigation li a').on('click', function (e) {
    e.preventDefault();

    scrollToElement($(this).attr('href'));

    return false;
  });

  $('.tpk-product-add-to-cart form').on('submit', function (e) {
    e.preventDefault();

    if ('undefined' === typeof wc_add_to_cart_params) {
      console.log('Dafuqs'); //TODO

      return;
    }

    const addToCartButton = $(this).find('button.single_add_to_cart_button');

    var data = {
      'product_id': addToCartButton.val()
    };

    $.each(addToCartButton.data(), function (key, value) {
      data[key] = value;
    });

    $.each(addToCartButton[0].dataset, function (key, value) {
      data[key] = value;
    });

    $.each($(this).serializeArray(), function (key, field) {
      data[field.name] = field.value;
    })

    // delete data.product_id;
    if (data.variation_id) {
      data.product_id = data.variation_id;
      delete data.variation_id;
      delete data['add-to-cart'];
    }

    $(document.body).trigger('adding_to_cart', [addToCartButton, data]);

    $.ajax({
      type: 'POST',
      url: wc_add_to_cart_params.wc_ajax_url.toString().replace('%%endpoint%%', 'add_to_cart'),
      data: data,
      dataType: 'json',
      beforeSend: function () {
        addToCartButton.addClass('loading');
      },
      complete: function () {
        addToCartButton.removeClass('loading');
      },
      success: function (response) {
        if (!response) {
          return;
        }

        if (response.error && response.product_url) {
          window.location = response.product_url;
          return;
        }

        // Redirect to cart option
        if (wc_add_to_cart_params.cart_redirect_after_add === 'yes') {
          window.location = wc_add_to_cart_params.cart_url;
          return;
        }

        // Trigger event so themes can refresh other areas.
        $(document.body).trigger('added_to_cart', [response.fragments, response.cart_hash, addToCartButton]);

        $('.tpk-menu-icon-trigger--cart').trigger('click');
      }
    });
  });

  $(document.body).on('added_to_cart removed_from_cart', function (event, fragments, cart_hash) {
    $('.tpk-menu-icon-trigger--cart .number-of-items').replaceWith(fragments['.cart-contents span.number-of-items']);
  })
})(window, jQuery);

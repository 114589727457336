;(function (_, $) {
  $(document).on('click touch', 'body.single-product article.product .swatch-wrapper', function () {
    const swatch = $(this).find('.swatch');

    $(this).addClass('active');
    $(this).siblings().removeClass('active');

    const color = swatch.data('id');

    let colorSelectors = $('#pa_culoare');

    if ($('article.product').hasClass('product-type-woosb')) {
      colorSelectors = $('.woosb-wrap .woosb-item-product .variations .value > select[name="attribute_pa_culoare"]');
    }

    colorSelectors.val(color);
    colorSelectors.trigger('change');

    $('.variation-select-title__value').removeClass('has-error').text(swatch.data('label'));

    //TODO: open single selection if one product does not have the selected color

    $('.tpk-single-add-to-cart-button').removeClass('disabled wc-variation-selection-needed');
  });

  $(document).on('click touch', '#tpk-toggle-multiple-color-select', function () {

    const singleColor = $(this).data('single_color');

    $('.woosb-wrap').toggleClass('visible', !singleColor);
    $('.fake-variation-select-wrapper').toggleClass('hidden', !singleColor);
    $(this).toggleClass('open');

    $(this).find('span').text(singleColor ? TPK.i18n['color_swatch.need_different_colors'] : TPK.i18n['color_swatch.need_single_color']);

    $(this).data('single_color', !singleColor);

    $(this).blur();
  });

  $(document).on('click touch', '.variations_form .tpk-single-add-to-cart-button.wc-variation-selection-needed', function () {
    $('.variation-select-title__value').addClass('has-error').text(TPK.i18n['color_swatch.please_select_color'])
  })

  $(document).ready(function () {
    if ($('.woosb-products .variations_form select[name="attribute_pa_culoare"]').length < 2) {
      $('#tpk-toggle-multiple-color-select').hide();
    }
  })

  $(document).on('woosb_check_ready', function () {
    const button = $('.tpk-single-add-to-cart-button');

    if(!button.hasClass('woosb-disabled')) {
      return;
    }

    button.removeClass('woosb-disabled').addClass('disabled wc-variation-selection-needed')
  });

  $(document).on('change', '.woosb-products form.variations_form select', function () {
    const variations = $(this).closest('.woosb-products').find('.variation select[name="attribute_pa_culoare"]');

    const value = $(this).val();
    $(this).toggleClass('selected', !!value);

    let allSelected = true;

    variations.each(function () {
      if (!$(this).val()) {
        allSelected = false;
      }
    });

    $('.tpk-single-add-to-cart-button').toggleClass('disabled wc-variation-selection-needed', !allSelected)
  });

  $(document).ready(function () {
    const colorSelector = $('#pa_culoare');

    if(colorSelector.length > 0) {
      const colorValue = colorSelector.val();

      if (colorValue) {
        $('.swatch[data-id="' + colorValue + '"]').trigger('click');
      }
    }
  })
})(window, jQuery);

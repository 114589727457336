;(function ($) {

    const modals = [];

    const getCurrent = () => modals.length ? modals[modals.length - 1] : null;
    const selectCurrent = () => {
        let selected = false;
        for (let i = modals.length - 1; i >= 0; i--) {
            if (modals[i].$overlay) {
                modals[i].$overlay.toggleClass('current', !selected).toggleClass('behind', selected);
                selected = true;
            }
        }
    }

    $.modal = function (el, options) {
        this.$body = $('body');
        this.options = $.extend({}, $.modal.defaults, options);
        this.options.doFade = !isNaN(parseInt(this.options.fadeDuration, 10));
        this.$overlay = null;

        if (this.options.closeExisting) {
            while ($.modal.isActive()) {
                $.modal.close(); // Close any open modals.
            }
        }

        this.canBeClosed = this.options.canBeClosed;

        modals.push(this);

        this.$elm = el;
        this.anchor = el;
        this.$body.append(this.$elm);
        this.open();
    }

    $.modal.prototype = {
        constructor: $.modal,

        open: function () {
            const m = this;
            this.block();
            this.anchor.blur();

            if (this.options.doFade) {
                setTimeout(function () {
                    m.show();
                }, this.options.fadeDuration);
            } else {
                this.show();
            }

            $(document).off('keydown.modal').on('keydown.modal', function (event) {
                const current = getCurrent();

                if (current.canBeClosed && event.which === 27 && current.options.escapeClose) {
                    current.close();
                }
            });

            if (this.canBeClosed && this.options.clickClose) {
                this.$overlay.click(function (e) {
                    if (e.target === this) {
                        $.modal.close();
                    }
                });
            }

            $(document).on('modal.close', '#' + this.$elm.attr('id'), function () {
                $.modal.close();
            })
        },

        close: function () {
            modals.pop();
            this.unblock();
            this.hide();

            if (!$.modal.isActive()) {
                $(document).off('keydown.modal');
            }
        },

        block: function () {
            this.$elm.trigger($.modal.BEFORE_BLOCK, [this._ctx()]);

            this.$body.css('overflow', 'hidden');

            this.$overlay = $('<div class="tpk-modal-overlay current"></div>').appendTo(this.$body);

            selectCurrent();

            if (this.options.doFade) {
                this.$overlay.css('opacity', 0).animate({opacity: 1}, this.options.fadeDuration);

            }
            this.$elm.trigger($.modal.BLOCK, [this._ctx()]);
        },

        unblock: function (now) {
            if (!now && this.options.doFade) {
                this.$overlay.fadeOut(this.options.fadeDuration, this.unblock.bind(this, true));
            } else {
                this.$overlay.children().appendTo(this.$body);
                this.$overlay.remove();
                this.$overlay = null;

                selectCurrent();

                if (!$.modal.isActive()) {
                    this.$body.css('overflow', '');
                }
            }
        },

        show: function () {
            this.$elm.trigger($.modal.BEFORE_OPEN, [this._ctx()]);
            if (this.options.showClose) {
                // noinspection HtmlUnknownAnchorTarget
                this.closeButton = $('<a href="#close-modal" rel="modal:close" class="close-modal"><svg width="16" height="16" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M2.29289 2.29289C2.68342 1.90237 3.31658 1.90237 3.70711 2.29289L13.7071 12.2929C14.0976 12.6834 14.0976 13.3166 13.7071 13.7071C13.3166 14.0976 12.6834 14.0976 12.2929 13.7071L2.29289 3.70711C1.90237 3.31658 1.90237 2.68342 2.29289 2.29289Z"/><path fill-rule="evenodd" clip-rule="evenodd" d="M13.7071 2.29289C14.0976 2.68342 14.0976 3.31658 13.7071 3.70711L3.70711 13.7071C3.31658 14.0976 2.68342 14.0976 2.29289 13.7071C1.90237 13.3166 1.90237 12.6834 2.29289 12.2929L12.2929 2.29289C12.6834 1.90237 13.3166 1.90237 13.7071 2.29289Z"/></svg></a>');
                this.$elm.append(this.closeButton);
            }
            this.$elm.addClass(this.options.modalClass).appendTo(this.$overlay);

            if (this.options.doFade) {
                this.$elm.css({opacity: 0, display: 'inline-block'}).animate({opacity: 1}, this.options.fadeDuration);
            } else {
                this.$elm.css('display', 'inline-block');
            }

            this.$elm.trigger($.modal.OPEN, [this._ctx()]);
        },

        hide: function () {
            this.$elm.trigger($.modal.BEFORE_CLOSE, [this._ctx()]);

            if (this.closeButton) {
                this.closeButton.remove();
            }

            const _this = this;

            if (this.options.doFade) {
                this.$elm.fadeOut(this.options.fadeDuration, function () {
                    _this.$elm.trigger($.modal.AFTER_CLOSE, [_this._ctx()]);
                });
            } else {
                this.$elm.hide(0, function () {
                    _this.$elm.trigger($.modal.AFTER_CLOSE, [_this._ctx()]);
                });
            }

            this.$elm.trigger($.modal.CLOSE, [this._ctx()]);
        },

        //Return context for custom events
        _ctx: function () {
            return {elm: this.$elm, $elm: this.$elm, $overlay: this.$overlay, options: this.options, $anchor: this.anchor};
        }
    };

    $.modal.close = function (event) {
        if (!$.modal.isActive()) {
            return;
        }

        if (event) {
            event.preventDefault();
        }

        const current = getCurrent();

        current.close();

        return current.$elm;
    };

    // Returns if there currently is an active modal
    $.modal.isActive = function () {
        return modals.length > 0;
    };

    $.modal.getCurrent = getCurrent;

    $.modal.defaults = {
        closeExisting: true,
        canBeClosed: true,
        escapeClose: true,
        clickClose: true,
        closeText: 'Close',
        modalClass: "modal",
        showClose: true,
        fadeDuration: null
    };

    // Event constants
    $.modal.BEFORE_BLOCK = 'modal:before-block';
    $.modal.BLOCK = 'modal:block';
    $.modal.BEFORE_OPEN = 'modal:before-open';
    $.modal.OPEN = 'modal:open';
    $.modal.BEFORE_CLOSE = 'modal:before-close';
    $.modal.CLOSE = 'modal:close';
    $.modal.AFTER_CLOSE = 'modal:after-close';

    $.fn.modal = function (options) {
        if (this.length === 1) {
            new $.modal(this, options);
        }
        return this;
    };

    const tpkOpenModal = (selector, settings = {}) => {
        $(selector).modal({
            fadeDuration: 150,
            canBeClosed: settings.canBeClosed ?? true,
            showClose: settings.showClose ?? true,
            closeExisting: settings.closeExisting ?? true,
        });
    }

    $(document).on('click.modal', 'a[rel~="modal:close"]', $.modal.close);

    $(document).on('click touch', '.js-open-modal', function () {

        const modalSelector = $(this).data('modal-selector');

        tpkOpenModal(modalSelector, {
            canBeClosed: $(this).data('modal-can-be-closed') ?? true,
            showClose: $(this).data('modal-show-close') ?? true,
            closeExisting: $(this).data('modal-close-existing') ?? true,
        })
    });

    $(document).on('click touch', '.js-close-modal', function () {
        $.modal.close();
    });

    window.tpkOpenModal = tpkOpenModal;
})(jQuery);
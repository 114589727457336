;(function (_, $) {
  $(document).on('click', '.tpk-cart-trigger-button', function (e) {
    $(this).addClass('tpk-mini-cart-open');
  });

  $(document).on('click', '.tpk-mini-cart-header--close, .tpk-mini-cart-overlay', function (e) {
    $('.tpk-cart-trigger-button').removeClass('tpk-mini-cart-open');
    e.stopPropagation();
  });

  $(document).on('wc_cart_button_updated', 'body', function (e, elements) {
    const button = $(elements[0]);
    button.siblings('.added_to_cart').remove();

    setTimeout(function () {
      button.removeClass('added');
    }, 2000);
  });

  $( document.body ).on( 'added_to_cart', function() {
    $('.tpk-menu-icon-trigger--cart').trigger('click');
  } );
})(window, jQuery);
;(function (_, $) {

  const couponCodeMutationObserver = new MutationObserver(function (mutations) {
    mutations.forEach(function (mutationRecord) {
      if (mutationRecord.attributeName !== 'class') {
        return;
      }

      if (mutationRecord.target.classList.contains('processing')) {
        $('.coupon-row').addClass('processing').block({
          message: null,
          overlayCSS: {
            background: '#fff',
            opacity: 0.6,
          },
        })
      } else {
        $('.coupon-row').addClass('processing').unblock()
      }
    });
  });

  $('.checkout_coupon').each(function () {
    couponCodeMutationObserver.observe(this, {
      attributes: true,
    })
  })

  $('.woocommerce-checkout')
    .on('click', '#tpk-fake-apply-coupon', function () {
      const val = $('#fake_coupon_code').val();

      $('.tpk-fake-coupon-error').empty();
      const couponCodeField = $('#coupon_code');

      couponCodeField.focus();
      couponCodeField.blur();

      couponCodeField.val(val);

      $('.woocommerce-form-coupon button[name="apply_coupon"]').trigger('click');
    })

  $(document.body)
    .on('update_checkout', function () {
      const error = $('.checkout_coupon .coupon-error-notice');

      if (error.length > 0 && error.html()) {
        $('.tpk-fake-coupon-error').html(error.html());
      }
    })


  $(document).ready(function () {
    if($.fn.selectWoo !== undefined) {
      $('#user_origin_field select').selectWoo()
    }
  });

})(window, jQuery);
import Swiper from "swiper";

import 'swiper/css/bundle';

;(function (_, $) {
  let timeout;

  $('.woocommerce-cart')
    .on('click', '.tpk-cart-product .actions .edit', function () {
      $(this).closest('.tpk-cart-product').addClass('edit-mode')
    })
    .on('click', '.tpk-cart-product .product-quantity', function () {
      if (timeout !== undefined) {
        clearTimeout(timeout);
      }

      timeout = setTimeout(function () {
        $('.woocommerce-cart').find('button[name="update_cart"]').first().trigger('click');
      }, 500);

    })
    .on('click', '#tpk-fake-apply-coupon', function () {
      const val = $('#fake_coupon_code').val();
      $('.tpk-fake-coupon-error').empty();

      const couponCodeField = $('#coupon_code');

      couponCodeField.focus();
      couponCodeField.blur();

      couponCodeField.val(val);

      $('.cart_totals').addClass('processing').block({
        message: null,
        overlayCSS: {
          background: '#fff',
          opacity: 0.6,
        },
      })

      $('.woocommerce-cart-form button[name="apply_coupon"]').trigger('click');
    })

  $(document.body)
    .on('updated_wc_div', function () {
      const error = $('.coupon .coupon-error-notice');

      if (error.length > 0 && error.html()) {
        $('.tpk-fake-coupon-error').html(error.html());
      }
    })

  const crossSellProductsSwiper = new Swiper(".cross-sells-slider-wrapper", {
    lazy: true,
    spaceBetween: 10,
    slidesPerView: 2,
    autoHeight: true,
    breakpoints: {
      640: {
        slidesPerView: 3
      },
      1024: {
        slidesPerView: 4
      }
    }
  });

})(window, jQuery);
;(function (_, $) {

  $(document).on('click touch', '.js-toggle-review-form', function () {
    $(this).closest('#review_form_wrapper').addClass('active');
  })

  $(document).on('click touch', '.js-toggle-more-reviews', function () {
    $(this).closest('.tpk-reviews-comments').addClass('visible');
  });

  const addReviewMessage = ($formWrapper, message, type) => {
    const messageWrapper = $('<div />', {
      class: 'tpk-reviews-message ' + (type === 'success' ? 'tpk-reviews-message--success' : 'tpk-reviews-message--error'),
      html: message,
    });

    const icon = type === 'success' ? 'fa-solid fa-check-square' : 'fa-solid fa-square-xmark';
    messageWrapper.prepend($('<i class="' + icon + '" aria-hidden="true"></i>'));

    $formWrapper.append(messageWrapper);

    setTimeout(function () {
      messageWrapper.remove();
    }, 5000);
  }

  $('#review_form_wrapper').off('submit').on('submit', '#commentform', function (event) {
    event.preventDefault();

    const formData = $(this).serialize() + '&action=tpk_ajax_reviews&submit=Send&comment_type=review';
    const $form = $(this);
    const formWrapper = $form.closest('#review_form_wrapper');

    $.ajax({
      url: TPK.ajaxUrl,
      type: 'POST',
      dataType: 'json',
      cache: false,
      data: formData,
      success: function (response) {
        if( !response.success ) {
          //TODO: handle failure
          return;
        }

        $form.closest('#review_form_wrapper').removeClass('active');
        $form[0].reset();

        addReviewMessage(formWrapper, response.data.message, 'success');
      },
      error: function (xhr, status, error) {
        addReviewMessage(formWrapper, xhr.responseText, 'error');
      }
    })

    return false;
  })

})(window, jQuery);
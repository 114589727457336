;(function ($) {
    'use strict';

    const cookieConsentCallback = (response, button, text) => {
        button.text(text);
        button.attr('disabled', false);

        // TODO
        /*if ( response.success && response.data ) {
          $('head').append( response.data?.head ?? '' );
          $('body').prepend( response.data?.body ?? '' );

          if ( $('#cookie-consent--marketing').is(':checked') ) {
            $('.update-cookies-map').remove();
            $('.sb-retailers__map').show();
          }
          else if( button.hasClass('accept-all-cookies') ) {
            $('.update-cookies-map').remove();
            $('.sb-retailers__map').show();
          }
        }*/
    }


    $(document).on('click', '.accept-all-cookies', function () {
        const button = $(this);
        const text = button.text();

        button.text('...');
        button.attr('disabled', true);

        if (typeof pmw === 'undefined') {
            console.log('pmw is undefined');

            return;
        }

        pmw.consent.api.acceptAll({
            duration: 90,
        });

        setTimeout(function () {
            button.removeAttr('disabled');
            button.text(text);

            button.closest('#tpk-cookie-consent-customize').find('input[type="checkbox"]').prop('checked', true);

            $.modal.close()
        }, 500)
    });

    $(document).on('click', '.cookies-option__read-more', function (e) {
        $(this).closest('.cookies-option').find('.cookies-option__description').stop().slideToggle();

        return false;
    });

    $(document).on('click', '#cookie-consent-customize-save', function () {
        const button = $(this);
        const text = button.text();

        button.text('...');
        button.attr('disabled', true);

        if (pmw === undefined) {
            console.log('pmw is undefined');
        }

        pmw.consent.api.updateSelectively({
            necessary: true,
            preferences: true,
            statistics: $('#cookie-consent--personalization').is(':checked'),
            marketing: $('#cookie-consent--marketing').is(':checked'),
            duration: 90,
        });

        setTimeout(function () {
            button.removeAttr('disabled');
            button.text(text);

            $.modal.close()
        }, 500);


    });

    $(document).on('click', '.cookie-consent-customize-trigger', function (e) {
        e.preventDefault();

        tpkOpenModal('#tpk-cookie-consent-customize', {
            canBeClosed: true,
            showClose: true,
            closeExisting: true,
        })
    })

    $(document).ready(function () {
        if ($('body').hasClass('needs-cookie-consent')) {
            $('#cookie-consent-wrapper-basic').modal({
                fadeDuration: 150,
                canBeClosed: false,
                showClose: false,
                closeExisting: false,
            });
        }
    })
})(jQuery);
import './libs/swipebox.js';
import './components/cart.js';
import './components/checkout.js';
import './components/color-swatches.js';
import './components/cookie-consent.js';
import './components/general-woocommerce.js';
import './components/jump-navigation.js';
import './components/mini-cart.js';
import './components/modals.js';
import './components/reorder.js';
import './components/reviews.js';
import './components/single-product.js';

import '../scss/tpk.scss'

if (import.meta.hot) {
  import.meta.hot.accept();
}

;(function (_, $) {
    $(document).ready(function(){

        $(document).on('click','.tpk_whatsapp a',function(e){
            if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
                e.preventDefault();
                window.location.href= $(this).data('mobile-href');
            }
        });

      if ($('.menu-toggle').is(':visible')) {
        $('.main-navigation')
          .find('.current-menu-item, .current_page_parent').addClass('sfHover')
          .find('.sub-menu').addClass('toggled-on');
      }
    });
})(window, jQuery);
;(function ($) {
    $(document).on('click touch', '.js-spy-nav-link', function () {
        const id = $(this).attr("href");
        const negativeOffset = $('body').hasClass('admin-bar') ? 100 : 65;

        $('html, body').animate({
            scrollTop: $(id).offset().top - negativeOffset
        }, 'slow');

        return false;
    });

    let animating = false;

    const bringHTMLElementIntoView = (element, wrapper) => {
        if(animating) {
            return;
        }
        const rect = element?.getBoundingClientRect()

        if (!rect) {
            return;
        }

        if(rect.left <= window.innerWidth && rect.left >= 0 && rect.right <= window.innerWidth && rect.right >= 0) {
            return;
        }

        let shouldAnimate = false;
        let animateProperties = {};

        if(rect.right > window.innerWidth ) {
            shouldAnimate = true;
            animateProperties = {
                scrollLeft: wrapper.scrollLeft() + rect.right - window.innerWidth + 20
            };

        } else if(rect.left < 0 ) {
            shouldAnimate = true;
            animateProperties = {
                scrollLeft: wrapper.scrollLeft() + rect.left - 20
            }
        }

        if(!shouldAnimate) {
            return;
        }

        wrapper.animate(animateProperties, {
            duration: 200,
            start: () => {
                animating = true;
            },
            complete: () => {
                animating = false;
            }
        })
    }

    $(document).ready(function () {

        const jumpNav = $('.tpk-product-jump-navigation');
        const mobileHeader = $('#mobile-header');

        const sectionIds = $('a.js-spy-nav-link');
        const negativeOffset = $('body').hasClass('admin-bar') ? 120 : 85;

        if (sectionIds.length === 0) {
            return;
        }

        $(window).scroll(function () {
            sectionIds.each(function () {
                const container = $(this).attr('href');
                const containerOffset = $(container).offset().top;
                const containerHeight = $(container).outerHeight();
                const containerBottom = containerOffset + containerHeight;
                const scrollPosition = $(document).scrollTop();

                const thisItem = $(this).parent();

                if (scrollPosition < containerBottom - negativeOffset && scrollPosition >= containerOffset - negativeOffset) {
                    bringHTMLElementIntoView(thisItem[0], jumpNav);
                    thisItem.addClass('active');
                } else {
                    thisItem.removeClass('active');
                }
            });
        });

        $(window).scroll(function () {
            jumpNav.css('top', +(mobileHeader.css('top').replace('px', '')) + mobileHeader.height());
        });
    });
})(jQuery);